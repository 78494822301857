<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      pre-select-filter
      :filter-names="filtersName"
      search-label="search"
      :status-options="statusOptions"
      telematic-rows
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:dataTable>
        <div class="data-wrapper v-data-table">
          <div
            v-if="!list.length > 0"
            class="d-flex justify-center text-muted"
          >
            No data available
          </div>
          <div
            v-for="(row, i) in list"
            :key="i"
            class="listing-row"
          >
            <div class="row-content">
              <div class="row-img">
                <v-img
                  contain
                  :src="row.model_image"
                >
                  <div class="img-text">
                    <Typography
                      :text="row.make_and_model"
                      text-clip
                    />
                  </div>
                </v-img>
              </div>
              <div class="row-wrapper">
                <div class="top-row-info-wrap">
                  <div class="top-row-left-content">
                    <div class="row-box-wrap">
                      <div class="text-muted">
                        {{ row.customer_name ? "Customer" : "Driver" }}
                      </div>
                      <Typography
                        :text="row.customer_name || row.driver_name"
                        text-clip
                        width="5rem"
                        class="nameWrap"
                      />
                    </div>
                    <div class="row-box-wrap">
                      <div class="text-muted">
                        Chassis Number
                      </div>
                      <template
                        v-if="
                          $admin.hasAccessTo('vehicle-telematics-detail.list')
                        "
                      >
                        <div class="open-link">
                          <router-link :to="goToDetailPage(row)">
                            {{ row.chasis_no }}
                          </router-link>
                        </div>
                      </template>
                      <template v-else>
                        <div>{{ row.chasis_no }}</div>
                      </template>
                    </div>
                    <div
                      v-if="row.vehicle_tech == 'ev'"
                      class="row-box-wrap"
                    >
                      <div class="text-muted">
                        SOC
                      </div>
                      <div>{{ row.soc }}</div>
                    </div>
                    <div
                      v-else
                      class="row-box-wrap"
                    >
                      <div class="text-muted">
                        Fuel Level
                      </div>
                      <div>{{ row.fuel_level }}L</div>
                    </div>
                    <div
                      v-if="row.vehicle_tech == 'ev'"
                      class="row-box-wrap"
                    >
                      <div class="text-muted">
                        Emission Saving
                      </div>
                      <div>{{ row.carbon_emission_saved }} (kgs)</div>
                    </div>
                    <div class="row-box-wrap">
                      <div class="text-muted">
                        Synced At
                      </div>
                      <div
                        class="sync-column"
                        :class="
                          row.vehicle_meta?.check_failed_reasons?.length > 0
                            ? 'text-danger'
                            : ''
                        "
                        @click="isShowSyncDetail = !isShowSyncDetail"
                      >
                        <span v-if="isShowSyncDetail">
                          {{ row.synced_at_detail | formatDate }}
                        </span>
                        <span v-else>{{ row.synced_at }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="statusWrap">
                    <span class="align-center">
                      <div class="text-muted">Status</div>
                      <StatusBadge :status="row.status" />
                    </span>
                  </div>
                </div>
                <div class="row-bottom-detail">
                  <div class="row-box-wrap">
                    <Icons
                      name="wheel-icon"
                      class="wheel-small-icon"
                    />
                    <span class="text-muted">Daily Distance: </span>
                    <span>{{ row.daily_distance }}</span>
                    <span class="text-muted SI-UNITS">
                      {{ $t("units.total_avg_range") }}
                    </span>
                  </div>
                  <div class="row-box-wrap">
                    <Icons
                      name="wheel-icon"
                      class="wheel-small-icon"
                    />
                    <span class="text-muted"> Daily Mileage: </span>
                    <span>{{ row.daily_mileage }}</span>
                    <span class="text-muted SI-UNITS">
                      {{
                        row.vehicle_tech == "ev"
                          ? $t("units.average_mileage")
                          : $t("units.average_mileage-ice")
                      }}
                    </span>
                  </div>
                  <div class="row-box-wrap">
                    <Icons
                      name="wheel-icon"
                      class="wheel-small-icon"
                    />
                    <span class="text-muted"> Total Distance: </span>
                    <span>{{ row.total_distance_travelled }}</span>
                    <span class="text-muted SI-UNITS">
                      {{ $t("units.total_avg_range") }}
                    </span>
                  </div>
                  <div class="row-box-wrap">
                    <Icons
                      name="wheel-icon"
                      class="wheel-small-icon"
                    />
                    <span class="text-muted"> Total Avg Mileage: </span>
                    <span>{{ row.total_avg_mileage }}</span>
                    <span class="text-muted SI-UNITS">
                      {{
                        row.vehicle_tech == "ev"
                          ? $t("units.average_mileage")
                          : $t("units.average_mileage-ice")
                      }}
                    </span>
                  </div>
                  <div class="row-box-wrap">
                    <Icons
                      name="wheel-icon"
                      class="wheel-small-icon"
                    />
                    <span class="text-muted"> Range: </span>
                    <span>{{ row.range }}</span>
                    <span class="text-muted SI-UNITS">
                      {{ $t("units.total_avg_range") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-action">
              <v-btn
                color="primary"
                small
                dark
                :disabled="row.is_demo_vehicle"
                class="locationBtn"
                @click="showVehiclePosition(row)"
              >
                <span class="locationText">Location</span>
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <div class="regNumberWrap">
                <div class="text-muted">
                  Reg Number
                </div>
                <div class="regNumber">
                  <Typography
                    :text="row.registration_no"
                    text-clip
                  />
                </div>
              </div>
              <div class="d-flex justify-space-between downloadBtnWrap">
                <v-btn
                  color="primary"
                  small
                  dark
                  :disabled="row.is_demo_vehicle"
                  @click="downloadCSV(row)"
                >
                  Download
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card>
            <v-text-field
              v-model="options.search"
              dense
              clearable
              :label="$t('select')"
              @change="fetchData"
            />
          </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
            flat
            class="action-toolbar"
          > -->

        <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
    <!--  -->
    <vehicle-position-modal
      v-if="positionModal"
      :is-visible-dialog="positionModal"
      :toggle-modal="togglePositionModal"
      :position="vehicleTelmatic"
    />
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
    Typography: () => import("@/components/base/Typography.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
    Icons: () => import("@/components/base/icons.vue"),
    //
    vehiclePositionModal: () => import("./utils/VehiclePositionModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //
      statusOptions: [
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
        { id: "offline", name: "Offline" },
      ],
      //
      timer: null,
      isShowSyncDetail: false,
      vehicleTelmatic: {},
      positionModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleTelematics/getLoading",
      meta: "vehicleTelematics/getMeta",
      headersList: "customerVehicles/getHeadersList",
      list: "vehicleTelematics/getList",
    }),
    _headers() {
      var headerArr = [];
      this.headersList.forEach((header) => {
        headerArr.push({
          // text: this.$t(header.key),
          text: header.heading,
          sortable: false,
          value: header.key,
          width: "10%",
        });
      });

      return headerArr;
    },

    filtersName() {
      if (this.authUser.isAdmin) {
        return ["search", "customer", "make", "model", "status"];
      } else {
        return ["search", "make", "model", "status"];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
    // "options.product": {
    //   handler: function () {
    //     this.fetchHeaders();
    //   },
    // },
    // "options.tenant_id": {
    //   handler: function () {
    //     this.fetchHeaders();
    //   },
    // },
    // "options.fleetId": {
    //   handler: function (v) {
    //     if (v) {
    //       this.fetchHeaders();
    //     }
    //   },
    // },
  },

  beforeDestroy() {
    const params = {
      name: "vehicle_telematics",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);

    //
    // this.$store.commit("customerVehicles/SET_CUSTOMERVEHICLES", []);
    // this.$store.commit("customerVehicles/SET_HEADERS", []);
    clearInterval(this.timer);
  },
  // mounted() {
  //   this.timer = setInterval(() => {
  //     this.fetchData();
  //     // if (this.options.tenant_id !== undefined) {
  //     //   this.$store.dispatch("customerVehicles/list", this.options);
  //     // }
  //   }, 30000);
  // },

  methods: {
    fetchHeaders: debounce(async function () {
      await this.$store.dispatch("customerVehicles/headersList", {
        product: this.options.product,
        fleetId: this.options.fleetId,
        oem_b2b_ids: this.options.oem_b2b_ids,
        tenant_id: this.options.tenant_id,
      });
    }, 300),
    async fetchData() {
      await this.$store
        .dispatch("vehicleTelematics/list", this.options)
        .then(() => {
          // if (this.options.page > (this.customerVehiclesMeta?.lastPage || 1)) {
          //   this.options.page = 1;
          // }
        });
    },
    //
    checkbatteryStatus(status) {
      if (status <= 20) {
        return "mdi-battery-charging-20";
      } else if (status <= 50) {
        return "mdi-battery-charging-50";
      } else if (status <= 80) {
        return "mdi-battery-charging-80";
      } else {
        return "mdi-battery-charging-100";
      }
    },
    showVehiclePosition(vehicleTelematics) {
      //   this.selectedRowId = vehicleTelematics.id;
      // this.vehicleTelmatic = [vehicleTelematics.lng, vehicleTelematics.lat];
      this.vehicleTelmatic = {
        lat: vehicleTelematics.lat,
        lng: vehicleTelematics.lng,
      };
      this.togglePositionModal();
      vehicleTelematics.is_open = true;
      this.selectedrow = vehicleTelematics;
      // this.itemRowBackground(vehicleTelematics);
    },
    togglePositionModal() {
      this.selectedrow ? (this.selectedrow.is_open = false) : "";
      return (this.positionModal = !this.positionModal);
    },

    downloadCSV(item) {
      // const params = {
      //   imei: item.iot_imei,
      // };
      // this.$store
      //   .dispatch("customerVehicles/downloadReport", params)
      //   .then((res) => {
      //     this.$store.dispatch("alerts/success", res.body.sucess_msg);
      //   })
      //   .catch((err) => {
      //     this.$store.dispatch("alerts/error", err?.response?.data?.message);
      //   });
      window.open(item.csv_url, "_blank");
    },
    goToDetailPage(item) {
      const { vehicle_id } = item;
      const params = { ...this.options };
      Object.keys(params).forEach((key) => {
        if (params[key] == null) {
          params[key] = undefined;
        }
      });
      return {
        name: "telematic_details",
        params: { vehicle_id },
        query: params,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif
$primaryColor: #23BDAA
.data-wrapper
  /*overflow-y: auto*/
  /*height: 60vh*/
  background: #fff
  overflow-y: auto
.listing-row
  user-select: none
  cursor: pointer
  display: flex
  padding: 0.5rem 0.5rem
  border: 1px solid #D4D4D4
  margin-bottom :1rem
  background: #ffff
  border-radius: 5px
  &:hover
    background: #eeeeee
    .row-content .row-img .img-text
      visibility: visible

  .row-content
    display: flex
    flex: 1
    align-items: center
    .row-img
      // width: 200px
      width: 130px
      .img-text
        visibility: hidden
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        width: 100%
        left: 0
        bottom: 0
        background: lightgray
        transition: all 0.2s linear
        :deep .typography
          .textEllipsis
            width: 130px !important
            padding: 0 0.45rem !important
            .text
              width: 100% !important
              white-space: nowrap !important
    .row-wrapper
      padding: 0 1rem
      display: flex
      flex-direction: column
      flex: 1
    .top-row-info-wrap
      display: flex
      flex-wrap: wrap
      .top-row-left-content
        display: flex
        flex-wrap: wrap
        width: 95%
        // gap: 3rem
        .row-box-wrap
          display: flex
          flex-direction: column
          padding: 0 2rem 0 0
          //min-width: 9rem
          font-size: 0.875rem !important
          font-family: $poppinSemi
          .text-muted
            font-family: $poppinReg
          .open-link
            font-size: 0.875rem !important
            font-family: $poppinSemi
      .statusWrap
        width: 3%
        margin-left: auto
        display: flex
        justify-content: flex-end
        font-size: 0.875rem !important
        text-align: center
        .align-center
          display: flex
          flex-direction: column
    .row-bottom-detail
      display: flex
      flex-wrap: wrap
      width: 100%
      padding: 0.5rem 0 0 0
      // gap: 0.5rem
      .row-box-wrap
        display: flex
        min-width: 9rem
        font-size: 0.875rem !important
        align-items: center
        font-family: $poppinSemi
        color: #000 !important
        padding: 0 0.95rem 0.5rem 0
        .wheel-small-icon
          width: 11px
          height: 11px
          margin: 0 0.3rem 0 0
          :deep .d-flex
            svg
              width: 100%
              height: 100%
        .text-muted
          font-family: $poppinReg
          color: #000 !important
          padding-right: 0.3rem
          &.SI-UNITS
            font-family: $poppinSemi
            color: #000 !important
            padding-left: 0.3rem
.regNumberWrap
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .text-muted
    font-family: $poppinReg
    color: #000 !important
    font-size: 0.875rem
  .regNumber
    font-family: $poppinMed
    color: $primaryColor !important
    font-size: 1rem
    :deep .typography
      .d-flex
        justify-content: center
.row-action
  border-left: 1px solid lightgray
  padding: 0 1rem
  display: flex
  flex-direction: column
  justify-content: center
  flex-basis: 10%
  align-items: center
.sync-column
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    color: #20a390 !important
.v-application
  .locationBtn
    background-color: transparent !important
    box-shadow: 0 0 0 !important
    color: $primaryColor !important
    padding: 0 !important
    min-width: unset !important
    height: auto !important
    text-transform: capitalize !important
    margin: 0 0 0.25rem 0
    .locationText
      color: $primaryColor
      padding: 0 0.45rem 0 0
      font-family: $poppinMed
      font-size: 0.875rem
    .v-icon
      color: $primaryColor
      font-size: 16px
.downloadBtnWrap
  width: 100% !important
  gap: 0 !important
  align-items: center !important
  justify-content: center !important
  .v-btn
    width: 80% !important
.nameWrap :deep .d-flex
  width: 9rem !important
.drawerOpen .nameWrap :deep .d-flex
  width: 11rem !important

@media (min-width: 1900px)
  .nameWrap :deep .d-flex
    width: 11rem !important
  .drawerOpen .nameWrap :deep .d-flex
    width: 13rem !important
</style>
